<template>
  <b-card
      class="product-edit-wrapper"
  >
    <!-- form -->
    <b-form id="productForm" class="edit-form mt-2">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>

        <b-col md="12">
          <b-card>
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称"
                    label-for="name"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="name"
                      size="sm"
                      v-model="product.name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="品牌名称"
                    label-for="brand_id"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="brand_id"
                      :options="brandArray"
                      :clearable="false"
                      v-model="brandId"
                      @input="changeBrandId($event)"
                      class="select-size-sm"
                      placeholder="请选择品牌"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="拼音码"
                    label-for="first_letter"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="first_letter"
                      size="sm"
                      v-model="product.first_letter"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="5"
                    label-cols-lg="3"
                    label="商品编码
                  (69码)"
                    label-for="code"
                    label-size="sm"
                    class="mb-1 required"

                >
                  <!--  v-model="product.code" -->
                  <div
                      v-for="(p, index) in productCodeArr"
                      :key="index">
                    <p>
                      <!--                    <b-form-input
                                              id="code"
                                              size="sm"
                                              :key="index"
                                              v-model="productCodeArr[index]"
                                              style="width: 70%; float: left"
                                              :disabled="index < judgeDisplay && judgeDisplay != undefined ? true: false"
                                              onkeyup="value=value.replace(/[\W]/g,'') "
                                              onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
                                              :readonly="isCodeReadonly"
                                          />-->
                      <v-select
                          id="code"
                          taggable
                          push-tags
                          size="sm"
                          :key="index"
                          style="width: 70%; float: left"
                          :disabled="isCodeReadonly||(index < judgeDisplay && judgeDisplay != undefined ? true: false)"
                          :options="codeOptions"
                          v-model="productCodeArr[index]"
                          @input="setCode(index,$event)"
                          @keyup.native="searchOneCode($event)"
                      />
                      <b-button @click="removeProduct(index)" :disabled="isCodeReadonly" size="sm"
                                v-if="index < judgeDisplay && judgeDisplay != undefined ? false: true">
                        删除
                      </b-button>
                      <b-button :disabled="isCodeReadonly" size="sm" 　style="visibility: hidden;" v-else>
                        无法删除
                      </b-button>
                    </p>
                  </div>
                </b-form-group>

                <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1"
                    @click="addInput"
                    :disabled="isCodeReadonly"
                >
                  新增商品编码(69码)
                </b-button>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="集团条码"
                    label-for="one_code"
                    label-size="sm"
                    class="mb-1"
                >
                  <!--                <div
                                      v-for="(p, index) in oneCodeArr"
                                      :key="index">
                                    <p>
                                      <b-form-input
                                          id="one_code"
                                          size="sm"
                                          :key="index"
                                          v-model="oneCodeArr[index]"
                                          readonly
                                      />
                                    </p>
                                  </div>-->
                  <b-form-input
                      id="one_code"
                      size="sm"
                      v-model="product.one_code"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品标签"
                    label-for="product_label"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="product_label"
                      size="sm"
                      v-model="product_label_name"
                      @click="showProductLabel()"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-modal
                  id="modal-single"
                  ok-only
                  ok-title="确认"
                  @ok="onSelectWHFromPeople"
                  cancel-title="取消"
                  centered
                  size="lg"
                  :title="'请选择标签'"
                  ref="productLabel"
              >
                <ProductLabelList
                    ref="whSelect" v-on:table="fromChildren($event,'from')"
                >
                </ProductLabelList>
              </b-modal>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">基础属性</div>
        </b-col>

        <b-col md="12">
          <b-card>
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品规格"
                    label-for="specification"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="specification"
                      size="sm"
                      v-model="product.specification"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="保质期(天)"
                    label-for="guarantee_period"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="guarantee_period"
                      size="sm"
                      type="number"
                      v-model="product.guarantee_period"
                      @change="changeBestShelfLife($event)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="8"
                    label-cols-lg="4"
                    label="允许采购"
                    label-for="enable_purchase"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="enable_purchase"
                      v-model="product.enable_purchase"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="基本单位"
                    label-for="product_unit"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="product_unit"
                      :options="getCodeOptions('product_unit')"
                      :clearable="true"
                      v-model="productUnit"
                      @input="changeSelect('product_unit',$event)"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="在架寿命期"
                    label-for="best_shelf_life"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="best_shelf_life"
                      size="sm"
                      v-model="best_shelf_life"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="8"
                    label-cols-lg="4"
                    label="允许流转"
                    label-for="enable_stock"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="enable_stock"
                      v-model="product.enable_stock"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="单份规格数量"
                    label-for="box_quantity"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <!-- :readonly="product.state==1" -->
                  <b-form-input
                      id="box_quantity"
                      size="sm"
                      v-model="product.box_quantity"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="默认税率"
                    label-for="tax_rate"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="tax_rate"
                      :options="getCodeOptions('tax_rate')"
                      :clearable="true"
                      v-model="taxRate"
                      class="select-size-sm"
                      @input="changeSelect('tax_rate',$event)"
                      placeholder="请选择默认税率"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="8"
                    label-cols-lg="4"
                    label="允许销售"
                    label-for="enable_sales"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="enable_sales"
                      v-model="product.enable_sales"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="产品分类"
                    label-for="category"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="chooseId"
                      size="sm"
                      readonly
                      placeholder="请选择分类"
                      v-b-modal.modal-select-category
                      v-model="category_name_value"
                  />
                </b-form-group>
              </b-col>
              <b-modal
                  id="modal-select-category"
                  no-close-on-backdrop
                  ok-title="确认"
                  cancel-title="取消"
                  centered
                  @ok="onSelectCategory"
                  size="lg"
                  title="分类选择"
              >
                <category-select
                    :categoryOptions="category_options"
                    :level="level"
                    ref="categorySel">
                </category-select>

              </b-modal>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">尺寸重量</div>
        </b-col>
        <b-col md="12">
          <b-card>
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="长度(cm)"
                    label-for="length"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="length"
                      size="sm"
                      v-model="product.length"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="毛重(g)"
                    label-for="gross_weight"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="gross_weight"
                      size="sm"
                      v-model="product.gross_weight"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="宽度(cm)"
                    label-for="net_weight"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="width"
                      size="sm"
                      v-model="product.width"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="净重(g)"
                    label-for="net_weight"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="net_weight"
                      size="sm"
                      v-model="product.net_weight"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="高度(cm)"
                    label-for="height"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="height"
                      size="sm"
                      v-model="product.height"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="净含量(ml)"
                    label-for="netweight"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="netweight"
                      size="sm"
                      v-model="product.netweight"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">图片素材</div>
        </b-col>

        <b-col md="12">
          <b-card>
            <b-row>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="产品图片"
                    label-for="image"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <attachment-upload v-if="product.loaded" :theme="'files'"
                                     :attachment_id="'image_id'"
                                     :id="product.image_id"
                                     :object_type="'product'"
                                     :object_id="product.product_id"
                                     @change="onUploadedForImage"
                  />

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="素材包"
                    label-for="attachments"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload v-if="product.loaded" :theme="'files'"
                                     :attachment_id="'attachments'"
                                     :id="product.attachments"
                                     :object_type="'product'"
                                     :object_id="product.product_id"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save(1)"
              v-if="readonly===0"
          >
            保存
          </b-button>

          <!--          <b-button
                            variant="primary"
                            class="mr-1"
                            @click="save(2)"
                            v-if="readonly===0"
                    >
                      提交
                    </b-button>-->

          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>

        <!--   old     -->
        <b-col md="12" style="display: none">
          <b-card>
            <b-row>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建组织"
                    label-for="company_creator"
                    label-size="sm"
                    class="mb-1"
                >

                  <b-form-input
                      id="company_creator"
                      size="sm"
                      v-model="product.company_creator"
                      readonly
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="使用组织"
                    label-for="company_owner"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="company_owner"
                      size="sm"
                      v-model="product.company_owner"
                      readonly
                  />
                </b-form-group>
              </b-col>

              <!--        <b-col md="4">
                        <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="标签"
                            label-for="company_owner"
                            label-size="sm"
                            class="mb-1"


                        >
                          <b-form-input
                              id="product_label"
                              size="sm"
                              v-model="product_label_name"
                              @click="showProductLabel()"
                          />
                        </b-form-group>
                      </b-col>-->

              <!--        <b-col md="4">
                        <b-form-group
                            label-cols="5"
                            label-cols-lg="3"
                            label="商品编码(69码)"
                            label-for="code"
                            label-size="sm"
                            class="mb-1 required"

                        >
                          &lt;!&ndash;  v-model="product.code" &ndash;&gt;
                          <div
                              v-for="(p, index) in productCodeArr"
                              :key="index">
                            <p>
                              <b-form-input
                                  id="code"
                                  size="sm"
                                  :key="index"
                                  v-model="productCodeArr[index]"
                                  style="width: 70%; float: left"
                                  :disabled="index < judgeDisplay && judgeDisplay != undefined ? true: false"
                                  onkeyup="value=value.replace(/[\W]/g,'') "
                                  onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
                              />
                              <b-button @click="removeProduct(index)" size="sm" v-if="index < judgeDisplay && judgeDisplay != undefined ? false: true">
                                删除
                              </b-button>
                              <b-button size="sm"　style="visibility: hidden;" v-else >
                                无法删除
                              </b-button>
                            </p>
                          </div>
                        </b-form-group>

                        <b-button
                            variant="primary"
                            size="sm"
                            class="mr-1"
                            @click="addInput"
                        >
                          新增商品编码(69码)
                        </b-button>
                      </b-col>-->

              <!--        <b-col md="4">
                        <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="商品名称"
                            label-for="name"
                            label-size="sm"
                            class="mb-1 required"
                        >
                          <b-form-input
                              id="name"
                              size="sm"
                              v-model="product.name"
                          />
                        </b-form-group>
                      </b-col>-->

              <!--        <b-col md="4">
                        <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="拼音首字母"
                            label-for="first_letter"
                            label-size="sm"
                            class="mb-1"
                        >
                          <b-form-input
                              id="first_letter"
                              size="sm"
                              v-model="product.first_letter"
                              readonly
                          />
                        </b-form-group>
                      </b-col>-->

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="进口商品"
                    label-for="is_import"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="is_import"
                      v-model="product.is_import"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                  <span>(影响过保自动计算结果)</span>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <div class="inner-card-title">基本信息</div>
              </b-col>

              <b-col md="4">
                <b-card header="基本信息">
                  <b-row>
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="6"
                                        label-cols-lg="3"
                                        label="商品规格"
                                        label-for="specification"
                                        label-size="sm"
                                        class="mb-1 required"
                                    >
                                      <b-form-input
                                          id="specification"
                                          size="sm"
                                          v-model="product.specification"
                                      />
                                    </b-form-group>
                                  </b-col>-->
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="基本单位"
                                        label-for="product_unit"
                                        label-size="sm"
                                        class="mb-1 required"
                                    >
                                      <v-select
                                          id="product_unit"
                                          :options="getCodeOptions('product_unit')"
                                          :clearable="true"
                                          v-model="productUnit"
                                          @input="changeSelect('product_unit',$event)"
                                          class="select-size-sm"
                                      />
                                    </b-form-group>
                                  </b-col>-->
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="单箱标准数量"
                                        label-for="box_quantity"
                                        label-size="sm"
                                        class="mb-1 required"
                                    >
                                      &lt;!&ndash; :readonly="product.state==1" &ndash;&gt;
                                      <b-form-input
                                          id="box_quantity"
                                          size="sm"
                                          v-model="product.box_quantity"
                                      />
                                    </b-form-group>
                                  </b-col>-->
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="助记码"
                          label-for="letter_name"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="letter_name"
                            size="sm"
                            v-model="product.letter_name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="描述"
                          label-for="remark"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="remark"
                            size="sm"
                            v-model="product.remark"
                        />
                      </b-form-group>
                    </b-col>

                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="分组"
                                        label-for="category"
                                        label-size="sm"
                                        class="mb-1 required"
                                    >
                                      <b-form-input
                                          id="chooseId"
                                          size="sm"
                                          readonly
                                          placeholder="请选择分类"
                                          v-b-modal.modal-select-category
                                          v-model="category_name_value"
                                      />
                                    </b-form-group>
                                  </b-col>-->

                    <!--              <b-modal
                                      id="modal-select-category"
                                      no-close-on-backdrop
                                      ok-title="确认"
                                      cancel-title="取消"
                                      centered
                                      @ok="onSelectCategory"
                                      size="lg"
                                      title="分组选择"
                                  >
                                    <category-select
                                        :categoryOptions="category_options"
                                        :level="level"
                                        ref="categorySel">
                                    </category-select>

                                  </b-modal>-->


                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="物料属性"
                          label-for="product_attribute"
                          label-size="sm"
                          class="mb-1"
                      >
                        <v-select
                            id="product_attribute"
                            :options="getCodeOptions('product_attribute')"
                            :clearable="true"
                            v-model="productAttribute"
                            @input="changeSelect('product_attribute',$event)"
                            class="select-size-sm"
                            placeholder="请选择物料属性"
                        />
                      </b-form-group>
                    </b-col>
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="品牌"
                                        label-for="brand_id"
                                        label-size="sm"
                                        class="mb-1 required"
                                    >
                                      <v-select
                                          id="brand_id"
                                          :options="brandArray"
                                          :clearable="true"
                                          v-model="brandId"
                                          @input="changeBrandId($event)"
                                          class="select-size-sm"
                                          placeholder="请选择品牌"
                                      />
                                    </b-form-group>
                                  </b-col>-->
                    <b-col md="12" class="hidden">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="产地"
                          label-for="area_id"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="area_id"
                            size="sm"
                            v-model="product.area_id"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="品牌标签"
                          label-for="product_brandlabel"
                          label-size="sm"
                          class="mb-1"
                      >
                        <v-select
                            id="product_brandlabel"
                            :options="getCodeOptions('product_brandlabel')"
                            :clearable="true"
                            v-model="productBrandlabel"
                            @input="changeSelect('product_brandlabel',$event)"
                            class="select-size-sm"
                            placeholder="请选择品牌标签"
                            style="z-index: auto"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                </b-card>
              </b-col>

              <b-col md="4">

                <b-card header="控制">
                  <b-row>
                    <!--              <b-col md="6">
                                    <b-form-group
                                        label-cols="8"
                                        label-cols-lg="4"
                                        label="允许采购"
                                        label-for="enable_purchase"
                                        label-size="sm"
                                        class="mb-1"
                                    >
                                      <b-form-checkbox
                                          name="check-button"
                                          switch
                                          inline
                                          id="enable_purchase"
                                          v-model="product.enable_purchase"
                                          value="1"
                                          unchecked-value="0"
                                      ></b-form-checkbox>
                                    </b-form-group>
                                  </b-col>-->
                    <!--              <b-col md="6">
                                    <b-form-group
                                        label-cols="8"
                                        label-cols-lg="4"
                                        label="允许销售"
                                        label-for="enable_sales"
                                        label-size="sm"
                                        class="mb-1"
                                    >
                                      <b-form-checkbox
                                          name="check-button"
                                          switch
                                          inline
                                          id="enable_sales"
                                          v-model="product.enable_sales"
                                          value="1"
                                          unchecked-value="0"
                                      ></b-form-checkbox>
                                    </b-form-group>
                                  </b-col>-->
                    <!--              <b-col md="6">
                                    <b-form-group
                                        label-cols="8"
                                        label-cols-lg="4"
                                        label="允许库存"
                                        label-for="enable_stock"
                                        label-size="sm"
                                        class="mb-1"
                                    >
                                      <b-form-checkbox
                                          name="check-button"
                                          switch
                                          inline
                                          id="enable_stock"
                                          v-model="product.enable_stock"
                                          value="1"
                                          unchecked-value="0"
                                      ></b-form-checkbox>
                                    </b-form-group>
                                  </b-col>-->
                    <b-col md="6">
                      <b-form-group
                          label-cols="8"
                          label-cols-lg="4"
                          label="允许生产"
                          label-for="enable_produce"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_produce"
                            v-model="product.enable_produce"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label-cols="8"
                          label-cols-lg="4"
                          label="允许委外"
                          label-for="enable_outside"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_outside"
                            v-model="product.enable_outside"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label-cols="8"
                          label-cols-lg="4"
                          label="允许资产"
                          label-for="enable_assets"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_assets"
                            v-model="product.enable_assets"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>

                <b-card header="财务信息">
                  <b-row>
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="默认税率"
                                        label-for="tax_rate"
                                        label-size="sm"
                                        class="mb-1 required"
                                    >
                                      <v-select
                                          id="tax_rate"
                                          :options="getCodeOptions('tax_rate')"
                                          :clearable="true"
                                          v-model="taxRate"
                                          class="select-size-sm"
                                          @input="changeSelect('tax_rate',$event)"
                                          placeholder="请选择默认税率"
                                      />
                                    </b-form-group>
                                  </b-col>-->
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="存货类别"
                          label-for="product_type"
                          label-size="sm"
                          class="mb-1"
                      >
                        <v-select
                            id="product_type"
                            :options="getCodeOptions('product_type')"
                            :clearable="true"
                            v-model="productType"
                            @input="changeSelect('product_type',$event)"
                            class="select-size-sm"
                            placeholder="请选择存货类别"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="税分类"
                          label-for="tax_type"
                          label-size="sm"
                          class="mb-1"
                      >
                        <v-select
                            id="tax_type"
                            :options="getCodeOptions('product_faxtype')"
                            :clearable="true"
                            v-model="taxType"
                            @input="changeSelect('tax_type',$event)"
                            class="select-size-sm"
                            placeholder="请选择税分类"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="4"
                          label-cols-lg="4"
                          label="结算成本价加减价比例(%)"
                          label-for="cost_added_rate"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="cost_added_rate"
                            size="sm"
                            v-model="product.cost_added_rate"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>


              </b-col>

              <b-col md="4">

                <b-card header="重量信息">
                  <b-row>
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="毛重"
                                        label-for="gross_weight"
                                        label-size="sm"
                                        class="mb-1"
                                    >
                                      <b-form-input
                                          id="gross_weight"
                                          size="sm"
                                          v-model="product.gross_weight"
                                      />
                                    </b-form-group>
                                  </b-col>-->
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="净重"
                                        label-for="net_weight"
                                        label-size="sm"
                                        class="mb-1"
                                    >
                                      <b-form-input
                                          id="net_weight"
                                          size="sm"
                                          v-model="product.net_weight"
                                      />
                                    </b-form-group>
                                  </b-col>-->
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="重量单位"
                          label-for="weight_unit"
                          label-size="sm"
                          class="mb-1"
                      >

                        <v-select
                            id="weight_unit"
                            :options="getCodeOptions('weight_unit')"
                            :clearable="true"
                            v-model="weightUnit"
                            @input="changeSelect('weight_unit',$event)"
                            class="select-size-sm"
                            placeholder="请选择重量单位"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card header="尺寸信息">
                  <!--            <b-col md="12">
                                <b-form-group
                                    label-cols="3"
                                    label-cols-lg="3"
                                    label="宽度(cm)"
                                    label-for="net_weight"
                                    label-size="sm"
                                    class="mb-1 required"
                                >
                                  <b-form-input
                                      id="width"
                                      size="sm"
                                      v-model="product.width"
                                      type="number"
                                  />
                                </b-form-group>
                              </b-col>-->
                  <!--            <b-col md="12">
                                <b-form-group
                                    label-cols="3"
                                    label-cols-lg="3"
                                    label="高度(cm)"
                                    label-for="height"
                                    label-size="sm"
                                    class="mb-1 required"
                                >
                                  <b-form-input
                                      id="height"
                                      size="sm"
                                      v-model="product.height"
                                      type="number"
                                  />
                                </b-form-group>
                              </b-col>-->
                  <!--            <b-col md="12">
                                <b-form-group
                                    label-cols="3"
                                    label-cols-lg="3"
                                    label="长度(cm)"
                                    label-for="length"
                                    label-size="sm"
                                    class="mb-1 required"
                                >
                                  <b-form-input
                                      id="length"
                                      size="sm"
                                      v-model="product.length"
                                      type="number"
                                  />
                                </b-form-group>
                              </b-col>-->
                </b-card>
                <!--            <b-card header="状态">-->
                <!--                <b-row>-->
                <!--                  <b-col md="12">-->
                <!--                    <b-form-group-->
                <!--                        label-cols="3"-->
                <!--                        label-cols-lg="3"-->
                <!--                        label="状态"-->
                <!--                        label-for="status"-->
                <!--                        label-size="sm"-->
                <!--                        class="mb-1"-->
                <!--                    >-->
                <!--&lt;!&ndash;                      {{getCodeLabel('product_status',product.status)}}&ndash;&gt;-->
                <!--                      <v-select-->
                <!--                          id="status"-->
                <!--                          :options="getCodeOptions('product_status')"-->
                <!--                          :clearable="true"-->
                <!--                          @input="changeSelect('status',$event)"-->
                <!--                          v-model="productStatus"-->
                <!--                          class="select-size-sm"-->
                <!--                      />-->
                <!--                    </b-form-group>-->
                <!--                  </b-col>-->
                <!--                  <b-col md="12">-->
                <!--                    <b-form-group-->
                <!--                        label-cols="3"-->
                <!--                        label-cols-lg="3"-->
                <!--                        label="启用"-->
                <!--                        label-for="state"-->
                <!--                        label-size="sm"-->
                <!--                        class="mb-1"-->
                <!--                    >-->
                <!--                      <v-select-->
                <!--                          id="state"-->
                <!--                          :options="getCodeOptions('state')"-->
                <!--                          v-model="state"-->
                <!--                          @input="changeSelect('state',$event)"-->
                <!--                          :clearable="true"-->
                <!--                          class="select-size-sm"-->
                <!--                      />-->
                <!--                    </b-form-group>-->
                <!--                  </b-col>-->
                <!--                </b-row>-->
                <!--            </b-card>-->


              </b-col>

              <b-col md="12">
                <div class="inner-card-title">库存</div>
              </b-col>

              <b-col md="4">

                <b-card header="库存控制">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="可以锁库"
                          label-for="enable_stock_lock"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_stock_lock"
                            v-model="product.enable_stock_lock"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card header="循环盘点">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="周期盘点"
                          label-for="enable_stock_inventory"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_stock_inventory"
                            v-model="product.enable_stock_inventory"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="盘点周期"
                          label-for="inventory_period"
                          label-size="sm"
                          class="mb-1"
                      >
                        <v-select
                            id="inventory_period"
                            :options="getCodeOptions('inventory_period')"
                            :clearable="true"
                            v-model="inventoryPeriod"
                            class="select-size-sm"
                            @input="changeSelect('inventory_period',$event)"
                            placeholder="请选择盘点周期"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="4"
                          label-cols-lg="4"
                          label="盘点固定日期(第几天)"
                          label-for="inventory_day"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="inventory_day"
                            size="sm"
                            v-model="product.inventory_day"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>

              </b-col>

              <b-col md="4">

                <b-card header="管理">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="保质期单位"
                          label-for="guarantee_period_unit"
                          label-size="sm"
                          class="mb-1 required"
                      >
                        <v-select
                            id="guarantee_period_unit"
                            :options="getCodeOptions('product_unit_period')"
                            :clearable="true"
                            v-model="guaranteePeriodUnit"
                            @input="changeSelect('guarantee_period_unit',$event)"
                            class="select-size-sm"
                            placeholder="请选择保质期单位"
                        />
                      </b-form-group>
                    </b-col>
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="保质期"
                                        label-for="guarantee_period"
                                        label-size="sm"
                                        class="mb-1 required"
                                    >
                                      <b-form-input
                                          id="guarantee_period"
                                          size="sm"
                                          type="number"
                                          v-model="product.guarantee_period"
                                          @change="changeBestShelfLife($event)"
                                      />
                                    </b-form-group>
                                  </b-col>-->
                    <!--              <b-col md="12">
                                    <b-form-group
                                        label-cols="3"
                                        label-cols-lg="3"
                                        label="在架寿命期"
                                        label-for="best_shelf_life"
                                        label-size="sm"
                                        class="mb-1 required"
                                    >
                                      <b-form-input
                                          id="best_shelf_life"
                                          size="sm"
                                          v-model="best_shelf_life"
                                          readonly
                                      />
                                    </b-form-group>
                                  </b-col>-->
                  </b-row>
                </b-card>
                <b-card header="库存成本">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="参考成本"
                          label-for="cost_reference"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="cost_reference"
                            size="sm"
                            v-model="product.cost_reference"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>

              </b-col>

              <b-col md="4">
                <b-card header="库存计划">
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                          label-cols="6"
                          label-cols-lg="6"
                          label="启用预警 最小库存"
                          label-for="enable_min_qty"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_min_qty"
                            v-model="product.enable_min_qty"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label-cols="6"
                          label-cols-lg="6"
                          label="启用预警 安全库存"
                          label-for="enable_safe_qty"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_safe_qty"
                            v-model="product.enable_safe_qty"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label-cols="6"
                          label-cols-lg="6"
                          label="启用预警 最大库存"
                          label-for="enable_max_qty"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_max_qty"
                            v-model="product.enable_max_qty"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label-cols="6"
                          label-cols-lg="6"
                          label="启用预警 再订货点"
                          label-for="enable_repeat_qty"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            id="enable_repeat_qty"
                            v-model="product.enable_repeat_qty"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="最小库存"
                          label-for="min_qty"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="min_qty"
                            size="sm"
                            v-model="product.min_qty"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="安全库存"
                          label-for="safe_qty"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="safe_qty"
                            size="sm"
                            v-model="product.safe_qty"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="最大库存"
                          label-for="max_qty"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="max_qty"
                            size="sm"
                            v-model="product.max_qty"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="再订货点"
                          label-for="repeat_qty"
                          label-size="sm"
                          class="mb-1"
                      >
                        <b-form-input
                            id="repeat_qty"
                            size="sm"
                            v-model="product.repeat_qty"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>

              </b-col>
              <b-col md="12">
                <div class="inner-card-title">相关文件</div>
              </b-col>
              <!--        <b-col md="12">
                        <b-form-group
                            label-cols="1"
                            label-cols-lg="1"
                            label="素材包"
                            label-for="attachments"
                            label-size="sm"
                            class="mb-1"
                        >
                          <attachment-upload v-if="product.loaded" :theme="'files'"
                                             :attachment_id="'attachments'"
                                             :id="product.attachments"
                                             :object_type="'product'"
                                             :object_id="product.product_id"
                                             @change="onUploaded"
                          />
                        </b-form-group>
                      </b-col>-->

              <!--        <b-col md="12">
                        <b-form-group
                            label-cols="1"
                            label-cols-lg="1"
                            label="产品图片"
                            label-for="image"
                            label-size="sm"
                            class="mb-1 required"
                        >
                          <attachment-upload v-if="product.loaded" :theme="'files'"
                                             :attachment_id="'image_id'"
                                             :id="product.image_id"
                                             :object_type="'product'"
                                             :object_id="product.product_id"
                                             @change="onUploadedForImage"
                          />

                        </b-form-group>
                      </b-col>-->
            </b-row>
          </b-card>
        </b-col>
        <!--   old     -->


      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>

import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormCheckbox,
  BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import CategorySelect from '@/views/apps/category/category-modal/CategorySelect'
import productStore from './productStore'
import categoryStore from "@/views/apps/category/categoryStore";
import brandStore from "@/views/apps/brand/brandStore";
import category from "../../../router/routes/apps/category";
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeLabel, getCodeOptions, avatarText, getCode} from '@core/utils/filter'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {getUserData} from "@/auth/utils";
import ProductLabelList from "@/views/apps/productlabel/productlabel_modal/ProductLabelList";

export default {
  components: {
    ProductLabelList,
    CategorySelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    BFormCheckbox,
    BCardBody,
    AttachmentUpload,

  },
  data() {
    return {
      id: ref(0),
      product: ref({}),
      productAttribute: {},
      productStatus: {},
      state: {},
      inventoryPeriod: {},
      guaranteePeriodUnit: {},
      productUnit: {},
      productBrandlabel: {},
      taxRate: {},
      productType: {},
      taxType: {},
      weightUnit: {},
      checked: true,
      isCodeReadonly: false,
      codeOptions: [],

      brandArray: [],
      brandId: {},
      product_label_id: "",
      product_label_name: "",

      best_shelf_life: "",
      productCodeArr: [''],
      // oneCodeArr: [''],
      isWhite: undefined,


      category_name_value: "",

      category_id: '',
      category_tow_id: '',
      category_three_id: '',
      level: 3,
      category_options: [],
      judgeDisplay: undefined,
      user: {},
      readonly: 0,
      resultProduct: true,
    }
  },
  methods: {
    onSelectWHFromPeople() {
      let returnData = {}
      if (this.$refs.whSelect.getSelected().length > 0) {
        returnData = this.$refs.whSelect.getSelected()[0]
        if (this.product_label_id == returnData.label_id) {
          this.resultProduct = true
        } else {
          this.resultProduct = false
        }

        if (returnData.label_name == '<空>') {
          this.product_label_id = 0
          this.product_label_name = returnData.label_name
        } else {
          this.product_label_id = returnData.label_id
          this.product_label_name = returnData.label_name
        }
        this.$forceUpdate()
      }
    },
    setCode(index, event) {
      if (event) {
        if (event.label) {
          event.label = event.label.replace(/[\W]/g, '')
          this.productCodeArr[index] = event.label
        } else {
          event = event.replace(/[\W]/g, '')
          this.productCodeArr[index] = event
        }
      } else {
        this.productCodeArr[index] = ''
      }
      //输入第一个条码、渲染表单
      if (index == 0 && event && event.data) {
        //todo
        /*this.product.name = event.data.barcodeName
        this.product.brand_id = event.data.brandId
        this.brandId = event.data.brandName
        this.product.length = event.data.length
        this.product.width = event.data.width
        this.product.height = event.data.height
        this.product.gross_weight = event.data.roughWeight
        this.product.net_weight = event.data.net_weight
        this.product.tax_rate = event.data.taxRate
        this.taxRate = event.data.taxRate
        this.product.one_code = event.data.internationalCode
        this.product.product_unit = event.data.unitId
        this.productUnit = getCode('product_unit', event.data.unitId)
        this.productCodeArr = [event.data.barcodeId]*/
        // this.productCodeArr.push()
        this.$forceUpdate()
      }
      if (this.productCodeArr && this.productCodeArr.length > 0) {
        this.product.one_code = this.productCodeArr[0]
      }
    },
    searchOneCode(event) {
      if (event.target.value.length > 5) {
        /*store.dispatch('product/queryBarcode',{code:event.target.value}).then(res => {
          console.log("queryBarcode")
          console.log(res.data)
          let list = res.data.data.ext.list
          this.codeOptions= [];
          for (let i = 0; i < list.length; i++) {
            this.codeOptions.push({"label":list[i]['barcodeId'],"data": list[i]})
          }
        })*/
        //this.codeOptions= [{"label":"123456789","data": {"name":"qasdfgqq"}}]
      }
    },
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('product')) store.registerModule('product', productStore)
    if (!store.hasModule('category')) store.registerModule('category', categoryStore)
    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('product')) store.unregisterModule('product')
      if (store.hasModule('category')) store.unregisterModule('category')
      if (store.hasModule('brand')) store.unregisterModule('brand')
    })

    const edit = function () {
      store.dispatch('product/edit', {id: this.id}).then(res => {

        this.product = res.data.data
        if (this.product.ext.label_name != null && this.product.ext.label_name != undefined) {
          this.product_label_name = this.product.ext.label_name
          this.product_label_id = this.product.ext.label_id
        }
        this.product.company_creator = 0
        this.product.company_owner = 0

        this.best_shelf_life = this.product.best_shelf_life

        this.productAttribute = getCode('product_attribute', this.product.product_attribute)
        this.productStatus = getCode('product_status', this.product.status)
        this.state = getCode('state', this.product.state)
        this.inventoryPeriod = getCode('inventory_period', this.product.inventory_period)
        this.guaranteePeriodUnit = getCode('product_unit_period', this.product.guarantee_period_unit)
        this.productUnit = getCode('product_unit', this.product.product_unit)
        this.productBrandlabel = getCode('product_brandlabel', this.product.product_brandlabel)
        this.taxRate = getCode('tax_rate', this.product.tax_rate)
        this.productType = getCode('product_type', this.product.product_type)
        this.taxType = getCode('product_faxtype', this.product.tax_type)
        this.weightUnit = getCode('weight_unit', this.product.weight_unit)
        // changeCategory(this.categoryId,this.product.category_id)
        if (this.id != 0) {
          if (this.product.code != null) {
            this.productCodeArr = this.product.code.substring(1, this.product.code.length - 1).split(",")
          }
          /*if (this.product.one_code != null) {
            this.oneCodeArr = this.product.one_code.substring(1, this.product.one_code.length - 1).split(",")
          }*/
        }
        if (this.productCodeArr.length > 1) {
          this.judgeDisplay = this.productCodeArr.length
        } else {
          if (this.productCodeArr[0].length != 0) {
            this.judgeDisplay = this.productCodeArr.length
          }
        }

        //编辑
        var ids = [];
        ids.push(this.product.category_id ? this.product.category_id : 0, this.product.category_tow_id ? this.product.category_tow_id : 0, this.product.category_three_id ? this.product.category_three_id : 0);
        if (ids[0]) {
          var content = "";
          console.log("reqParam")
          console.log(ids)
          let reqParam = ids.join(",")
          console.log(reqParam)
          store.dispatch('category/searchByIds', {category_ids: reqParam}).then(res => {
            let a = res.data.data;
            for (let i = 0; i < a.length; i++) {
              content += a[i].name;
              content += "/";
              this.category_options.push({"label": a[i].name, "category_id": a[i].category_id});
            }
            this.category_name_value = content;
          });
        }

        store.dispatch('brand/search').then(res => {
          let a = res.data.data.list
          for (let i = 0; i < a.length; i++) {
            if (a[i].brand_id === this.product.brand_id) {
              this.brandId = a[i].name
              this.isWhite = a[i].is_white
              if (this.isWhite == '1') {
                this.isCodeReadonly = true
              }
            }
          }
        })

        getBrand(this.brandArray)
      })
    }

    const onSelectCategory = function () { //赋值回显
      this.product.category_id = this.$refs.categorySel.categoryId;
      this.product.category_tow_id = this.$refs.categorySel.categoryTowId;
      this.product.category_three_id = this.$refs.categorySel.categoryThreeId;
      this.category_options = [];
      this.category_options.push(
          {
            "label": this.$refs.categorySel.categoryName,
            "category_id": this.$refs.categorySel.categoryId,
          },
          {
            "label": this.$refs.categorySel.categoryTowName,
            "category_id": this.$refs.categorySel.categoryTowId,
          },
          {
            "label": this.$refs.categorySel.categoryThreeName,
            "category_id": this.$refs.categorySel.categoryThreeId,
          },
      );
      var new_content = this.$refs.categorySel.categoryName + "/";
      new_content += this.$refs.categorySel.categoryTowName + "/";
      new_content += this.$refs.categorySel.categoryThreeName + "/";
      this.category_name_value = new_content;
    }


    const view = function () {
      store.dispatch('product/view', {id: this.id}).then(res => {
        this.product = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function (saveId) {
      console.log("1qqq23")
      console.log(this.productCodeArr)
      let message = JSON.stringify(this.productCodeArr)
          .replace(",\"\"", "")
          .replace(/\"/g, "")
          .replace("\[", "")
          .replace("\]", "")
      let messArr = message.split(",")
      for (let i = 0; i < messArr.length; i++) {
        if (messArr[i] === messArr[i + 1]) {
          toast.error('商品69码不可以重复!!!')
          return false
        }
      }
      message = ',' + message + ','
      if (message.length === 2) {
        message = ''
      }

      console.log("123")
      console.log(message)
      this.product.guaranteePeriodUnit = 1
      this.product.code = message
      //正整数
      const cRegExp = /^[1-9]\d*$/
      if (this.product.name === undefined || this.product.name === "") {
        toast.error('商品名称必填!')
        return false
      }
      if (this.product.brand_id === undefined || this.product.brand_id === "") {
        toast.error('品牌名称必选!')
        return false
      }
      if (this.isWhite != 1 && (this.product.code === undefined || this.product.code === "")) {
        toast.error('商品编码(69码)必填!')
        return false
      }
      /*if (this.product_label_id == undefined) {
        toast.error('请选择商品标签!')
        return false
      }*/
      if (this.product.specification === undefined || this.product.specification === "") {
        toast.error('商品规格必填!')
        return false
      }
      if (this.product.product_unit === undefined || this.product.product_unit === "") {
        toast.error('基本单位必填!')
        return false
      }
      if (this.product.box_quantity === undefined || this.product.box_quantity === "") {
        toast.error('单份规格数量必填!')
        return false
      }
      if (!cRegExp.test(this.product.box_quantity)) {
        toast.error('单份规格数量必须是正整数!')
        return false
      }
      if (this.product.category_three_id === undefined || this.product.category_three_id === "" || this.product.category_three_id === 0) {
        toast.error('产品分类必选三级分类!')
        return false
      }
      if (this.product.guarantee_period === undefined || this.product.guarantee_period === "") {
        toast.error('保质期(天)必填!')
        return false
      }
      if (!cRegExp.test(this.product.guarantee_period)) {
        toast.error('保质期(天)必须是正整数!')
        return false
      }
      if (this.product.best_shelf_life === undefined || this.product.best_shelf_life === "") {
        toast.error('在架寿命期必选!')
        return false
      }
      if (!cRegExp.test(this.product.best_shelf_life)) {
        toast.error('在架寿命期必须是正整数!')
        return false
      }
      if (this.product.tax_rate === undefined || this.product.tax_rate === "") {
        toast.error('默认税率必选!')
        return false
      }
      if (this.product.length === undefined || this.product.length === "") {
        toast.error('长度必填!')
        return false
      }
      if (!cRegExp.test(this.product.length)) {
        toast.error('长度必须是正整数!')
        return false
      }
      if (this.product.width === undefined || this.product.width === "") {
        toast.error('宽度必填!')
        return false
      }
      if (!cRegExp.test(this.product.width)) {
        toast.error('宽度必须是正整数!')
        return false
      }
      if (this.product.height === undefined || this.product.height === "") {
        toast.error('高度必填!')
        return false
      }
      if (!cRegExp.test(this.product.height)) {
        toast.error('高度必须是正整数!')
        return false
      }
      if (this.product.gross_weight === undefined || this.product.gross_weight === "") {
        toast.error('毛重必填!')
        return false
      }
      if (this.product.net_weight === undefined || this.product.net_weight === "") {
        toast.error('净重必填!')
        return false
      }
      /*if (this.product.netweight === undefined || this.product.netweight === "") {
        toast.error('净含量必填!')
        return false
      }*/
      if (this.product.netweight !== undefined && this.product.netweight !== "" && !cRegExp.test(this.product.netweight)) {
        toast.error('净含量必须是正整数!')
        return false
      }
      //yang 产品图片必填
      if (this.product.image_id === undefined || this.product.image_id === "") {
        toast.error('请上传商品名称、69码图片!')
        return false
      }

      store.dispatch('product/save', {
        ...this.product,
        label_id: this.product_label_id,
        // isWhite:this.isWhite,
        resultProduct: this.resultProduct
      }).then(res => {
        if (res.data.code === 0) {
          //保存并提交
          let productId = res.data.data.product_id;
          if (saveId === 2) {
            changeStatus(productId)
          }
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-product-list'});

        } else {
          toast.error(res.data.data)
        }
      })
    }


    const changeStatus = function (productId) {
      store.dispatch('product/changeStatus', {productid: productId, status: 3}).then(res => {
        if (res.data.code === 0) {
          toast.success("审核成功!")
          const memo = "提交审核商品"
          store.dispatch('modifylog/save', {object_type: 1, object_id: productId, modify_content: memo}).then(res => {
            console.log("添加成功修改日志：")
          })
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const changeSelect = function (key, event) {
      if (event != null) {
        this.product[key] = event.value
      } else {
        this.product[key] = undefined
      }

    }


    const changeBrandId = function (event) {
      this.product.brand_id = event.brand_id
      this.isWhite = event.is_white
      //新增
      if (this.id == 0) {
        if (event.is_white == '1') {
          //商品编码只读
          this.isCodeReadonly = true
          this.productCodeArr = ['']
          this.product.one_code = ''
        } else {
          this.isCodeReadonly = false
        }
      }

    }


    const getBrand = function (d) {
      store.dispatch('brand/searchNormal').then(res => {
        let a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          d.push({"label": a[i].name, "brand_id": a[i].brand_id, "is_white": a[i].is_white})
        }
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.product[id] = result
    }

    const onUploadedForImage = function (id, attachment, result) {
      this.product[id] = result
      this.product.image = attachment[0].path
    }

    const changeBestShelfLife = function (event) {
      store.dispatch('product/reduce', {data: event}).then(res => {
        this.product.best_shelf_life = res.data.data
        this.best_shelf_life = res.data.data
      })
    }

    const addInput = function () {
      this.productCodeArr.push('')
    }

    const removeProduct = function (index) {
      if (this.productCodeArr.length > 1) {
        let newArr = []
        for (let i = 0; i < this.productCodeArr.length; i++) {
          if (i === index) {
            continue
          }
          newArr.push(this.productCodeArr[i])
        }
        this.productCodeArr = newArr
      } else {
        this.productCodeArr = ['']
      }
      if (this.productCodeArr && this.productCodeArr.length > 0) {
        this.product.one_code = this.productCodeArr[0]
      }
    }
    const showProductLabel = function () {
      this.$refs['productLabel'].show()
    }
    const fromChildren = function (data, modal) {

      if (modal == 'from') {
        this.product_label_id = data.label_id
        this.product_label_name = data.label_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }


    return {
      edit,
      view,
      cancel,
      save,
      avatarText,
      toTime,
      toDate,
      getCodeLabel,
      getCodeOptions,
      changeSelect,
      changeBrandId,
      onSelectCategory,
      getBrand,
      onUploaded,
      onUploadedForImage,
      changeBestShelfLife,
      addInput,
      removeProduct,
      showProductLabel,
      fromChildren,
      changeStatus
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.readonly = this.$route.params.readonly || null;
    if (this.$route.params.readonly === 0 || this.id === 0) {
      this.readonly = 0
    }
    const userData = getUserData()
    this.user = userData
    if (this.user.role_id == 14) {
      this.readonly = 0
    }
    getCodeOptions('product_attribute')
    this.edit()
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
